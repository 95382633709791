import React, {useCallback} from 'react';
import {connect} from 'react-redux';
import {getPatientsData} from "../../../utils/selectors";
import Loader from "react-spinners/BeatLoader";
import isEmpty from "../../../utils/helpers";
import {showModal} from "../../../actions/modal.actions";

const PatientInfo = (
    {
        patientInfo,
        showModal,
        showDetails,
    }) => {

    const showPatientInfo = useCallback(() => {
        showModal("PATIENT_INFO_MODAL", patientInfo);
    }, [patientInfo]);

    let preferencesText = patientInfo.therapyGoal || "-";
    if (preferencesText.length > 150) {
        preferencesText = preferencesText.substring(0, 147) + "...";
    }

    return  (
        <>
            <div className="card">

                {!isEmpty(patientInfo)
                ? <>
                    {/*<div className="patient">
                        {patientPhoto
                            ? <img className="patient-image" src={URL.createObjectURL(new Blob([patientPhoto]))} alt="Patient Photo"/>
                            : <i className="patient-image fa fa-user" />
                        }
                        <div className="patient-body">
                            <h5 className="patient-name">{patientInfo.firstName + " " + patientInfo.lastName}</h5>
                            {patientInfo.familyStatus && (
                                <p className="patient-status">{patientInfo.familyStatus}</p>
                            )}
                        </div>
                    </div>*/}
                    <div className="card-body">

                        <h5 className="card-title">
                            <button onClick={showPatientInfo}>Client Preferences</button>
                        </h5>

                        <p style={{marginBottom: "0px"}}>{preferencesText}</p>

                        {showDetails !== false && patientInfo.address &&
                        <p className="card-note">{patientInfo.address}</p>
                        }

                        {/*<button className="card-link">More</button>*/}

                    </div>
                </> : <>
                    <div className="card-body">
                        <Loader size={5} color={"#ccc"}/>
                    </div>
                </> }

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const patientInfo = getPatientsData(state).patientInfo;
    const patientPhoto = getPatientsData(state).patientPhoto;

    return {
        patientInfo,
        patientPhoto,
    };
};

const mapDispatchToProps = {
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);